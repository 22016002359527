<template>
  <div class="videoRoom" @contextmenu="handleMouse">
    <!-- <div> -->
    <!-- 左视频 -->
    <div class="room-left">
      <div class="room-title">
        <div class="elp">
          {{ detail.title }}
        </div>
        <div class="time">
          <i class="iconfont icon-a-Group1339"></i>
          直播时间：{{ detail.startTime | format("yyyy.MM.dd HH:mm")
          }}<!-- -{{
            detail.endTime | format("HH:mm")
          }} -->
        </div>
      </div>
      <!-- 视频上方提示 -->
      <div class="room-Top" v-if="tipsShow">
        <!-- 左侧提示 -->
        <div class="wrapTopsCenTopL">
          <img src="@/assets/img/kecheng/tipsIcon.png" />
          <span>课程视频版权所有，禁止任何形式得转载！并未经本公司书面许可的使用行为，我公司均保留追究法律责任的权利。</span>
        </div>
        <!-- 右侧关闭 -->
        <div class="wrapTopsCenTopR" @click="tipsShow = false">
          <span>关闭</span>
          <img src="@/assets/img/kecheng/classIcon1123.png" />
        </div>
      </div>
      <!-- 视频模块： -->
      <div id="playbackPanel" :class="fullScreenInfo ? 'full_screen' : ''">
        <div class="finish" v-if="finish">
          <div class="finishTips" v-if="finishTips">
            <div class="finishTipsText">直播已结束，回放生成中...</div>
            <div class="finishTipsBtn" @click="isOk">确定</div>
          </div>
        </div>
        <div class="iconfont icon-quanping1" @click="fullScreen"></div>
        <div :id="'liveLog'">
          <img :src="copyright.videoLogo" style="object-fit: contain" />
        </div>
      </div>
      <!-- jiangshi -->
      <div class="detail-ter m-t-20 flex-c">
        <div class="ter-list">
          <div class="terbox">
            <el-image class="ter-img" :src="liveDetail ? liveDetail.tImg : ''" fit="cover"></el-image>
          </div>
          <span class="ter">讲师：{{ liveDetail ? liveDetail.terName : "" }}</span>
        </div>
      </div>
    </div>
    <!-- 右头像/聊天 -->
    <div class="room-right">
      <!-- 讲师头像 -->
      <div class="playbackPlayerWrap">
        <div id="playbackPlayer"></div>
        <img v-if="volume == 0" @mouseover="mouseover" @click="muteOrPlay" src="@/assets/img/Home/mute.png"
          class="soundImage" />
        <div class="mask">
          <div class="maskBlock"></div>
          <div class="soundWrap" @mouseleave="mouseleave" @mouseover="mouseover">
            <el-slider class="slider" v-model="volume" vertical height="100px" @input="soundChange"></el-slider>
          </div>
          <img v-if="volume != 0" @mouseover="mouseover" @click="muteOrPlay" src="@/assets/img/Home/sound.png"
            id="soundImage" />
          <img v-if="volume == 0" @mouseover="mouseover" @click="muteOrPlay" src="@/assets/img/Home/mute.png"
            id="soundImage" />
        </div>
      </div>
      <livebo-fang-chater class="room" :playid="showId" :id="courseId" :classId="classId" :IsPurchase="IsPurchase"
        :detail="detail" />
    </div>
    <!-- </div> -->
    <div class="haveNot" @touchmove.stop.prevent="stop()" v-if="!IsPurchase">
      <div>
        <img class="imga" src="@/assets/img/kecheng/No1.png" />
        <img class="imgb" src="@/assets/img/kecheng/close.png" @click.stop="goBack()" />
        <span>您暂未购买该课程哦~</span>
      </div>
    </div>

    <ratingPopup :show="ratingPopupShow" :type="1" :courseId="courseId" :chapterId="showId" :teacherId="teacherId"
      @close="closeRatingPopup"></ratingPopup>
    <!-- 是否可以观看弹窗 -->
    <div class="lookBg" v-if="showLookDetail">
      <div class="ifLook">
        <div class="prompt">提示</div>
        <div class="contents">
          您当前正在<span>{{ lookDeatil ? lookDeatil.terminal : "" }}</span>观看<span>{{ lookDeatil ? lookDeatil.chapterName : ""
          }}</span>,请关闭后再行观看其他章节。
        </div>
        <div class="button" @click="closeLook">关闭</div>
      </div>
    </div>
  </div>
</template>
<script type="text/javascript">
import { getLbClassCourseById } from "@/api/home";
import { getInfo, getDomain, getToken } from "@/api/cookies";
import {
  Know,
  courseCheckPlay,
  onLineLiveCourseDataSendBatch,
  judgeIsComment,
} from "@/api/know";
let know = new Know();
import ratingPopup from "@/components/ratingPopup.vue";
import liveboFangChater from "@/components/Know/liveboFangChater.vue";
import format from "date-fns/format";
import { newWebSocket } from "@/common/utils/newWebSocket.js";
import Vue from "vue";
export default {
  components: { liveboFangChater, ratingPopup },
  props: [
    "detail",
    "userid",
    "roomid",
    "recordid",
    "courseId",
    "classId",
    "showId",
    "type",
    "ccConfig",
    "IsPurchase",
    "startTime",
    "isLive",
    "roid",
    "reid",
    "teacherId",
    "chapterName",
    "typekc",
  ],
  /*type 1 直播  2录播 录播  3公开*/
  data() {
    return {
      tipsShow: true,
      //直播 用户名称
      viewername: "",
      // 公聊
      msgList: [],
      // 输入框
      my_msg: "",
      timer: 0,
      intervalTimer: null,
      setInterval: null, //计时器
      setIntervals: null,
      liveInfo: {},
      liveDetail: {
        userid: "",
        roomid: "",
        recordid: "",
      }, //课程详情
      resultTime: "", // 跳转时间
      volume: 0, // 音量
      finish: false,
      finishTips: false,
      firstPlayFlag: true,
      joinTime: "",
      fullScreenInfo: false,
      isfullscreen: false,
      copyright: null,
      timeStamp: null,

      ratingPopupShow: false,
      teacherId: null,
      judgeCommen: null,
      lookDeatil: null,
      showLookDetail: false,
      currentPlayTimeBatch: [],
    };
  },
  watch: {
    isfullscreen() {
      if (!this.isfullscreen) {
        this.fullScreenInfo = false;
      }
    },
  },
  beforeDestroy() {
    this.cleanTimeInterval();
    this.loadgetLbClassCourse();
    clearInterval(this.setInterval);
    clearInterval(this.setIntervals);
    $.DW.destroy();
    this.stopLearn();
  },
  mounted() {
    document.addEventListener("fullscreenchange", () => {
      this.isfullscreen = !this.isfullscreen;
    });
    document.addEventListener("mozfullscreenchange", () => {
      this.isfullscreen = !this.isfullscreen;
    });
    document.addEventListener("webkitfullscreenchange", () => {
      this.isfullscreen = !this.isfullscreen;
    });
    document.addEventListener("msfullscreenchange", () => {
      this.isfullscreen = !this.isfullscreen;
    });
    this.joinTime = format(new Date(), "yyyy-MM-dd HH:mm:ss");

    console.log("------live----mmm");

    // source  终端 1 PC 2 M  3 APP 4 IOS 5  聚学U APP  6  聚学U IOS   7 聚学堂小程序 program   8 聚学U小程序
    // showId 视频节id
    // type  1直播 2录播 3音频 4图文 5面授

    // 连接ws
    let sub = {
      courseType: "1",
      userid: this.userInfo.id,
      tenantid: this.userInfo.tenantId || null,
      source: 1,
      // classId: this.classId ? this.classId : undefined,
      // chapterId: this.showId,
      // groupId: null,
      // kid: this.courseId,
      // chapterName: this.chapterName,
      // timeStamp: new Date().getTime(),
    };
    // let data = Vue.prototype.export(sub)
    let url = `/${Vue.prototype.Base64.encode(JSON.stringify(sub))}`;
    console.log("-----ws---", url);
    if (this.userInfo) {
      // 限制只能一个人只能看一个课
      courseCheckPlay({
        courseType: "1",
        userId: this.userInfo.id,
        chapterId: this.showId,
        source: 1,
      }).then((res) => {
        console.log(res, "-----------------可以观看吗live-------");
        if (res.code == 0) {
          newWebSocket.init({
            url: url,
            onopen: (msg, data) => {
              console.log(msg, data);
              this.addLearn();
            },
            onmessage: (data) => {
              console.log(data);
            },
            onclose: (data) => {
              console.log(data);
            },
          });
        } else {
          this.lookDeatil = res.msg;
          this.showLookDetail = true;
        }
      });
    }
    this.judgeIsComment();
  },
  async created() {
    console.log(this.IsPurchase, "------IsPurchaserqlrqlrql");
    if (!this.IsPurchase) {
      return;
    }
    console.log(
      this.typekc,
      "========================man1===================="
    );
    this.copyright = getDomain();
    getLbClassCourseById(
      this.courseId,
      this.classId && this.typekc != 16 ? this.classId : undefined,
      this.classId && this.typekc == 16 ? this.classId : undefined
    ).then((res) => {
      this.liveDetail = res.data.details;
    });
    if (this.ccConfig) {
      let index = this.ccConfig.indexOf("?");
      if (index != -1) {
        var str = this.ccConfig.substr(index + 1);
        var strs = str.split("&");
        for (var i = 0; i < strs.length; i++) {
          this.liveInfo[strs[i].split("=")[0]] = strs[i].split("=")[1];
        }
      }
    } else {
      this.liveInfo.userid = "41A331E332E32281";
      this.liveInfo.roomid = this.roomid;
      this.liveInfo.recordid = this.recordid;
    }
    this.setInterval = setInterval(() => {
      this.timer++;
    }, 1000);
    this.setIntervals = setInterval(() => {
      this.loadgetLbClassCourse();
    }, 180000);

    this.userInfo = getInfo();
    this.calculatingTime();
    await this.$nextTick(function () {
      this.liveStart();
    });
  },

  methods: {
    closeLook() {
      this.showLookDetail = false;
      window.close();
    },
    // 是否评论过
    judgeIsComment() {
      judgeIsComment({
        stuId: this.userInfo.id,
        type: "1",
        courseId: this.courseId,
        chapterId: this.showId,
        sectionId: "",
      }).then((res) => {
        this.judgeCommen = res.data;
      });
    },
    /* 添加学习记录 */
    async addLearn() {
      console.log("--------addLearn------------");
      this.timeStamp = new Date().getTime();
      let data = {
        courseType: "1",
        userid: this.userInfo.id,
        tenantid: this.userInfo.tenantId || null,
        classId: this.classId ? this.classId : undefined,
        chapterId: this.showId,
        groupId: null,
        kid: this.courseId,
        timeStamp: this.timeStamp,
        chapterName: this.chapterName,
        source: 1,
      };
      newWebSocket.sendMessage(JSON.stringify(data));
    },
    // 视频暂停发送ws学习记录暂停调用
    stopLearn() {
      console.log("--------stopLearn------------");
      let data = {
        courseType: "1",
        tenantid: this.userInfo.tenantId || null,
        classId: this.classId ? this.classId : undefined,
        userid: this.userInfo.id,
        groupId: null,
        chapterId: this.showId,
        kid: this.courseId,
        timeStamp: this.timeStamp,
        chapterName: this.chapterName,
        source: 1,
      };
      newWebSocket.sendMessage(JSON.stringify(data));
    },
    handleMouse(e) {
      e.preventDefault();
    },
    fullScreen() {
      let fullscreenElement = document.fullscreenElement;
      let element = document.documentElement;
      if (fullscreenElement) {
        /* 退出全屏 */
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
        this.fullScreenInfo = false;
      } else {
        /* 进入全屏 */
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        } else if (element.msRequestFullscreen) {
          element.msRequestFullscreen();
        } else if (element.webkitRequestFullscreen) {
          element.webkitRequestFullscreen();
        }
        this.fullScreenInfo = true;
        console.log(this.fullScreenInfo);
      }
    },
    isOk() {
      this.finishTips = false;
      window.close();
    },
    /* 点击静音/播放 */
    muteOrPlay() {
      if (this.volume == 0) {
        this.volume = 100;
      } else {
        this.volume = 0;
      }
    },
    /* 调音量 */
    soundChange(val) {
      $.DW.setVolume(val / 100);
    },
    /* 鼠标移入音量图表 */
    mouseover() {
      let soundWrap = document.getElementsByClassName("soundWrap");
      soundWrap[0].style.display = "block";
    },
    /* 鼠标移出音量图表 */
    mouseleave() {
      let soundWrap = document.getElementsByClassName("soundWrap");
      soundWrap[0].style.display = "none";
    },
    /* 计算时间 */
    /* 2021-12-15 16:00:00 */
    calculatingTime() {
      let targetTime = new Date(this.startTime); // 开始直播时间
      let targetTimeTamp = targetTime.getTime();
      let nowTime = new Date(); // 现在时间
      let timestamp = nowTime.getTime();
      let result = Math.floor((timestamp - targetTimeTamp) / 1000);
      this.resultTime = result;
    },
    stop() {
      return;
    },
    // 初始化
    async liveStart() {
      let that = this;
      await setTimeout(() => {
        if (this.isLive == 1) {
          $.DW.isShowBar(1);
        }
        $.DW.config({
          userId: that.userid,
          roomId: that.roomid, //直播间Id
          recordId: that.recordid, //直播回放Id
          isH5play: true,
          fastMode: true,
          viewername: that.userInfo.userName,
          viewertoken: JSON.stringify({
            id: that.showId,
            buy: that.IsPurchase ? 1 : 0,
          }),
        });
        //动态显示登陆名
        window.onload = function () { };
        function on_cc_login_error() {
          console.log("登录失败");
        }
        function on_cc_login_success(data) {
          console.log("登录成功");
        }
      }, 100);
      window.on_spark_player_pause = function () {
        console.log("--暂停121212");
        that.cleanTimeInterval();
        let video = document.getElementById("playbackVideo");
        if (that.finish) {
          video.pause();
          console.log("已暂停");
          this.stopLearn();
        } else {
          console.log("----jixu");
          video.play();
        }
      };
      window.on_cc_live_player_load = function () {
        console.log("播放器加载完成");
        setTimeout(() => {
          if (that.firstPlayFlag) {
            $.DW.seek(that.resultTime);
            $.DW.setVolume(0);
            $.DW.play();
            that.firstPlayFlag = false;
          }
        }, 1000);
      };
      window.on_cc_player_ready = function () {
        console.log("播放器准备完成");
      };
      window.on_spark_player_end = function () {
        let video = document.getElementById("playbackVideo");
        video.pause();
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
        that.fullScreenInfo = false;
        $.DW.switchFullScreen(false);
        if (that.judgeCommen == "false") {
          that.ratingPopupShow = true;
        }
        that.cleanTimeInterval();
        that.stopLearn();
        console.log(989898989);
        that.finish = true;
        that.finishTips = true;
        console.log("播放结束");
      };
      /*   if (this.status == 0 && this.classProductLine == 3) {
        $.DW.isShowBar(1);
      } */
      window.on_player_start = () => {
        //直播开始
        console.log("--start");
        if (that.intervalTimer == null) {
          that.timeInterval();
          // this.addLearn()
        }
      };
      window.on_spark_player_resume = () => {
        //恢复播放
        console.log("---恢复播放");
        if (that.intervalTimer == null) {
          that.timeInterval();
        }
      };
    },
    async loadgetLbClassCourse() {
      let data = $.DW.getPlayerTime(); // 获取当前播放时间
      know
        .getCurTracking(
          this.courseId,
          this.showId,
          this.type,
          0,
          data,
          this.timer
        )
        .then((res) => { });
    },


    /* 学习记录 */
    timeInterval() {//自测通过
      console.log("学习率live")

      var currentPlayTime = format(new Date(), "yyyy-MM-dd HH:mm:ss")
      this.currentPlayTimeBatch.push(currentPlayTime)

      let that = this
      if ((this.currentPlayTimeBatch.length % 30) == 0 && this.currentPlayTimeBatch.length > 0) {
        that.sendTimePointBatch()
      }

      this.intervalTimer = setTimeout(this.timeInterval, 1000);
    },

    sendTimePointBatch() {
      const obj = {
        userId: this.userInfo.id,
        classId: this.classId,
        courseId: this.courseId,
        showId: this.showId,
        tenantId: this.userInfo.tenantId,
        courseType: 1,
        agentType: 0,
        isLive: 1,
        liveBeginTime: this.detail.startTime,
        entryLiveTime: this.joinTime,
        currentPlayTimeBatch: this.currentPlayTimeBatch,
        source: 1,
      };

      onLineLiveCourseDataSendBatch(obj).then((res) => {
        if (res.code == 500) {
          clearTimeout(this.intervalTimer);
          this.intervalTimer = null;
        } else {//上报成功
          this.currentPlayTimeBatch = []
        }
      });

    },

    cleanTimeInterval() {
      clearTimeout(this.intervalTimer);
      this.intervalTimer = null;
    },
    scrollbottom() {

    },

    closeRatingPopup() {
      this.ratingPopupShow = false;
    },
  },
};
</script>
<style lang="less" scoped>
// 视频上方提示
.room-Top {
  width: 100%;
  height: 40px;
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 58px;
  z-index: 99;

  // 左侧提示
  .wrapTopsCenTopL {
    width: auto;
    height: 16px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    img {
      width: 16px;
      height: 16px;
      margin: 0px 10px 0px 10px;
    }

    span {
      width: auto;
      height: auto;
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #f15817;
    }
  }

  // 右侧关闭
  .wrapTopsCenTopR {
    width: auto;
    height: 12px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;

    span {
      width: auto;
      height: auto;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #a8abbe;
    }

    img {
      width: 11px;
      height: 11px;
      margin: 0px 10px 0px 5px;
    }
  }
}

/* 讲师 */
.detail-ter {
  width: 100%;
  height: 58px;
  background: #383838;
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  padding: 0px 20px;

  .ter {
    color: #fff;
  }
}

/* 章节名称 */
.room-title {
  height: 58px;
  background: #383838;
  border-radius: 8px 8px 0px 0px;
  width: 100%;
  overflow: hidden;
  scrollbar-width: none;
  opacity: 1;
  font-size: 22px;
  font-family: Microsoft YaHei-Regular;
  font-weight: 400;
  color: #ffffff;
  line-height: 22px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;

  .elp {
    width: 550px;
  }

  .time {
    font-size: 14px;
    font-family: Microsoft YaHei-Regular;
    font-weight: 400;
    color: #ff5e51;
    line-height: 14px;
    flex-shrink: 0;

    .iconfont {
      font-size: 14px;
      margin-right: 8px;
    }
  }
}

// 中间视频
.videoRoom {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  padding: 32px 0px;
  background: #222222;

  // 左视频
  .room-left {
    width: 880px;
    height: 100%;
    background: #222222;
    margin-right: 20px;
    position: relative;

    #playbackPanel {
      width: 100%;
      height: 495px;
      border-radius: 0px 0px 10px 10px;
      opacity: 1;
      position: relative;

      .icon-quanping1 {
        position: absolute;
        top: 20px;
        right: 20px;
        cursor: pointer;
      }

      .finish {
        width: 100%;
        height: 495px;
        opacity: 1;
        background-color: #1b1b1b;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        .finishTips {
          width: 440px;
          height: 240px;
          background-color: #000000;
          border-radius: 8px;
          text-align: center;
          color: #ffffff;

          .finishTipsText {
            font-size: 20px;
            line-height: 150px;
          }

          .finishTipsBtn {
            width: 108px;
            height: 48px;
            border: 1px solid #ffffff;
            border-radius: 4px;
            line-height: 48px;
            margin-left: 167px;
            cursor: pointer;
          }
        }
      }

      #liveLog {
        position: absolute;
        right: 50px;
        bottom: 50px;

        img {
          height: 40px;
        }
      }
    }

    .prism-player {
      width: 100%;
      height: 100%;
    }

    video {
      width: 100%;
      height: 100%;
    }
  }

  // 右头像/聊天
  .room-right {
    height: 100%;
    flex-shrink: 0;

    .playbackPlayerWrap {
      width: 320px;
      height: 240px;
      position: relative;

      #playbackPlayer {
        width: 320px;
        height: 240px;
        border-radius: 8px 8px 8px 8px;
        opacity: 1;
        overflow: hidden;
        margin-bottom: 20px;
        position: relative;
      }

      /* #soundImage{
        position: absolute;
        z-index: 10;
        width: 16px;
        height: 16px;
        right: 20px;
        bottom: 20px;
      } */

      .mask {
        width: 100%;
        height: 50px;
        background-color: rgba(0, 0, 0, 0.5);
        position: absolute;
        bottom: 0px;
        display: none;

        #soundImage {
          position: absolute;
          width: 16px;
          height: 16px;
          right: 20px;
          bottom: 20px;
        }

        #soundImage:hover {
          width: 20px;
          height: 20px;
        }

        .soundWrap {
          display: none;

          .slider {
            position: absolute;
            height: 150px;
            bottom: 5px;
            right: 13px;
          }
        }
      }

      .soundImage {
        width: 80px;
        height: 80px;
        position: absolute;
        top: 50px;
        left: 50%;
        margin-left: -40px;
      }
    }

    img {
      width: 135px;
      height: 113px;
      margin-top: 26px;
    }

    span {
      width: auto;
      height: auto;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #6a6a6a;
    }

    .room {
      width: 320px;
      height: 371px;
      background: #383838;
      border-radius: 8px 8px 8px 8px;
      opacity: 1;
      overflow: hidden;
      overflow-y: scroll;
    }
  }

  // 聊天框
  .wrapTopsCenCenRC {
    width: 100%;
    height: 346px;

    // 聊天框顶部
    .wrapTopsCenCenRCa {
      width: 100%;
      height: 40px;
      background: #464646;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #efefef;
    }

    // 聊天框内容
    .wrapTopsCenCenRCb {
      width: 100%;
      height: 260px;
      overflow: auto;

      // overflow-y:scroll;
      // overflow-x:hidden
      // 内容盒子
      .wrapTopsCenCenRCbs {
        width: 85%;
        height: auto;
        margin: 20px auto;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;

        // 左侧头像
        .wrapTopsCenCenRCbsL {
          width: 32px;
          height: 30px;
          border-radius: 50%;
          margin-right: 10px;

          img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }
        }

        // 右侧昵称/留言
        .wrapTopsCenCenRCbsR {
          width: 85%;
          height: auto;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;

          span {
            width: auto;
            height: auto;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #b0b0b0;
          }

          p {
            width: 100%;
            height: auto;
            overflow: hidden;
            font-size: 12px;
            margin-top: 5px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
          }
        }
      }
    }

    // 聊天框底部
    .wrapTopsCenCenRCc {
      width: 100%;
      height: 46px;
      background: #5d5d5d;
      display: flex;
      justify-content: center;
      align-items: center;

      // 内容盒子
      .wrapTopsCenCenRCcs {
        width: 85%;
        height: 30px;
        background: #7a7a7a;
        border-radius: 15px;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        // 输入框
        input {
          width: 70%;
          height: 100%;
          border: none;
          outline: none;
          background: #7a7a7a;
          color: #ffffff;
        }

        // 修改输入框预输入文字
        input::-webkit-input-placeholder {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #efefef;
        }

        // 发送
        div {
          width: 24%;
          height: 100%;
          background: #a1a1a1;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 0px 15px 15px 0px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #efefef;
          cursor: pointer;
        }
      }
    }

    //隐藏滚动条
    ::-webkit-scrollbar {
      display: none;
      scrollbar-width: none !important;
      -ms-overflow-style: none;
    }
  }
}

.playbackPlayerWrap:hover .mask {
  display: block !important;
}

.full_screen {
  overflow: hidden !important;
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh !important;
  z-index: 99999;
}

.lookBg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;

  .ifLook {
    width: 500px;
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #ccc;

    .contents {
      width: 380px;
      margin: 20px auto;

      &>span {
        color: red;
      }
    }

    .button {
      width: 120px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      border-radius: 10px;
      border: 1px solid #ccc;
      cursor: pointer;
    }
  }
}
</style>
